import React, { useEffect, useState } from "react";
import highlight from './Frame 418.png';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import breadcumb from '../bread (1).jpg';
import { Link } from "react-router-dom";
import n1 from './new1 (1).jpeg'


import n3 from './new1 (2).jpg'
import n2 from './new1 (3).jpg'
import n4 from './new1 (1).jpg'

export default function Blog() {
  const [blogs, setBlogs] = useState([]); // State to hold the blog data
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [error, setError] = useState(null); // State to handle any errors

  // Fetching data from the API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://dev-wkl.indiawkl.com/api/resource/WKL%20News?fields=["*"]');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data.data); // Assuming the data is in data.data
      } catch (err) {
        setError(err); // Set error state if the request fails
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    fetchBlogs();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // Format to "20 March 2024"
  };

  // Function to truncate text safely
  const truncateText = (text, wordLimit) => {
    if (typeof text !== 'string') return ''; // Ensure text is a string
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  return (
    <>
      <Header />

      <div className="breadcrumb">
        <img src={breadcumb} alt="Breadcrumb Image" className="breadcrumb-image" />
        <span className="breadcrumb-text"> News & Updates</span>
      </div>
<section className="highlight-article">

  <h3>Kabaddi Association Certificate
  </h3>
  <br></br>
  <div className="row">
    <div className="col-md-3 col-12">
      <img src={n1} className="img-fluid"/>
    </div>

    <div className="col-md-3 col-12">
      <img src={n2} className="img-fluid"/>
    </div>

    <div className="col-md-3 col-12">
      <img src={n3} className="img-fluid"/>
    </div>


    <div className="col-md-3 col-12">
      <img src={n4} className="img-fluid"/>
    </div>
  </div>
</section>
      <section id="blog-page">
      <h3>News & Updates
  </h3>
  <br></br>
        <div className="container-fluid">
          <div className="row">
            {loading && <p>Loading blogs...</p>} {/* Loading message */}
            {error && <p>Error fetching blogs: {error.message}</p>} {/* Error message */}
            {blogs.map((blog, index) => ( // Mapping over the fetched blogs
              <div className="col-md-3 col-12" key={index}>
                <Link  target="_blank" to={blog.link}> {/* Link to the blog details */}
                  <div className='left-highlight'>
                    <div className='img-left'>

                   
                      <img  src={`https://dev-wkl.indiawkl.com/${blog.image}`}className='img-fluid' alt={blog.title} />
                    </div>
                    <div className='left-caption'>
                      <div className='date'>
                        <p>
                          <span className='highlights-blink'>HIGHLIGHTS</span>&nbsp;&nbsp;
                          {formatDate(blog.date)} | {blog.channel}
                        </p>
                      </div>
                      <div className='heading'>
                        <h4>{truncateText(blog.title,9)}</h4> {/* Limit title to 14 words */}
                        <p>{truncateText(blog.description,10)}</p> {/* Limit description to 20 words */}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
